@import "~epsilon-blueprint/styles/global-variables";
@import "~epsilon-blueprint/styles";

@import "./styles/base-template";
@import "./styles/grid";
@import "./styles/general";
@import "./styles/modals";
// TO-DO - Width property override to make it flexible, to be removed once custom width feature 
// is provided in Bluepring component 
@import "./styles/collapsible-panel";

@import "~ng-pick-datetime-ex/assets/style/picker.min.css";
@import "~@circlon/angular-tree-component/css/angular-tree-component.css";
@import "@epsilon/core-ui/assets/cui/sass/cui";
