/*
  Do not modify this file
  Any changes to this file will result in major side effects

  These are global overrides to Bootstrap's grid
  because Blueprint uses 20px gutters
*/

.container,
.container-fluid
{
  padding-right: 20px;
  padding-left: 20px;
}

.row
{
  margin-right: -20px;
  margin-left: -20px;
}

.col,
[class^="col-"]
{
  padding-right: 20px;
  padding-left: 20px;
}