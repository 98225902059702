@import "@epsilon/core-ui/assets/cui/sass/cui";
.center
{
  width: max-content;
  margin: 5em auto;
}

.u-mb-5
{
  margin-bottom: 5px;
}

.u-ml-10
{
  margin-left: 10px;
}

.u-mr-10
{
  margin-right: 10px;
}

.u-mt-10
{
  margin-top: 10px;
}

.u-mb-10
{
  margin-bottom: 10px;
}

.u-mr-20
{
  margin-right: 20px;
}

.u-ml-20
{
  margin-left: 20px;
}

.u-mb-20
{
  margin-bottom: 20px;
}

.u-mt-20
{
  margin-top: 20px;
}

.u-ml-30
{
  margin-left: 30px;
}

.u-mr-30
{
  margin-right: 30px;
}

.u-mb-30
{
  margin-bottom: 30px;
}

.u-p-20
{
  padding: 20px;
}

.u-py-25
{
  padding-top: 25px;
  padding-bottom: 25px;
}

.u-py-50
{
  padding-top: 50px;
  padding-bottom: 50px;
}

.u-pt-25
{
  padding-top: 25px;
}

.u-pb-25
{
  padding-bottom: 25px;
}

.u-pb-20
{
  padding-bottom: 20px;
}

.u-pb-10
{
  padding-bottom: 10px;
}

.u-h-100
{
  height: 100vh;
}

.opacity-100
{
  opacity: 1;
}

.opacity-50
{
  opacity: 0.5;
}

/* stylelint-disable scss/selector-nest-combinators */
:focus,
button:focus
{
  box-shadow: $box-shadow-focus-default;
  outline: 0;
}
/* stylelint-enable scss/selector-nest-combinators */

.overlay
{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba($coconut, 0.5);
}

.wrapper
{
  display: flex;
  align-items: stretch;
  width: 100%;
}

.rule-build-help
{
  border: 2px dotted $border-color-secondary;
}

.tool-tip
{
  color: $accent-color-primary;
}

.u-min-h-2-5
{
  min-height: 2.5rem;
}

.section-line
{
  border-bottom:1px solid $border-color-secondary;
}

.background-cover
{
  background-color: #F5F6FA;
}

#ae-table {
  .Core-RadioGroup-content {
    flex-flow: column;
  }
}

#single-model-package-icon {
  background-color: var(--theme_badge-new-1_color-1);
  color: var(--theme_inform-info-1_color-1);
  border-radius: 20px;
  width: 20px;
  text-align: center;
}

#multi-model-package-icon {
  @extend #single-model-package-icon;
  background-color: var(--theme_inform-incomplete-2_color-1);
  color: var(--theme_badge-incomplete-1_color-2);
}

.indent-text {
  text-indent: 15px;
}

.indent-text-with-margin {
  @extend .indent-text;
  margin-bottom: 1.5rem!important;
}

.remove-margin {
  & .Core-FormField.has-bottom-margin {
    margin: 0 !important;
  }
}