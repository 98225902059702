.modal-backdrop
{
  background: rgba($midnight, 0.5);

  &.show
  {
    opacity: 1;
  }
}

.modal-content
{
  box-shadow: $box-shadow-primary;
  border-radius: 0;
  border: 0;
}

.modal-header
{
  align-items: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid $mist;
  border-bottom-color: $border-color-secondary;
  padding: 10px 12px 10px 20px;
}

.close
{
  opacity: 1;
  color: $font-color-secondary;

  &:not(:disabled)
  {
    &:not(.disabled)
    {
      &:focus,
      &:hover
      {
        opacity: 1;
        color: $font-color-primary;
      }
    }
  }
}

.modal-body
{
  border-right: 1px solid $mist;
  border-left: 1px solid $mist;
  padding: 30px 20px;

  & p
  {
    &:last-child
    {
      margin-bottom: 0;
    }
  }
}

.modal-footer
{
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid $border-color-secondary;
  padding: 12px 12px 12px 20px;
  background: $cloud;

  & .btn
  {
    margin: 0;
  }

  & .btn-text
  {
    margin-left: 10px;
  }

  .Modal-titleIcon {
    transform: scale(1.5);
    margin-left: 5px;
    margin-right: 8px;
    color: var(--theme_inform-warn-1_color-1);
}
}
